import {Button, Form, Grid, Modal, ModalProps} from "semantic-ui-react";
import {ChangeEvent, FC, useEffect, useState} from "react";
import {connect} from "react-redux";
import DatePicker from "react-datepicker";
import axios from "axios";
import {toast} from "react-toastify";
import parse from 'date-fns/parse';
import {format} from 'date-fns';

const DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mm a';

interface ComponentProps {
    // open: boolean;           <- ModalProps
    // onClose: () => void;     <- ModalProps
    toggle: (posting: any) => void;
    editPosting?: boolean;
    post?: any;
    startDate?: Date
    endDate?: Date
}

type OwnProps = ComponentProps & ModalProps;

interface PropsFromState {
    currentUser: string;
    currentCategory: string | null;
    userEntities: any;
    roles: any;
    sppStaff: boolean;
}

const PostingModal: FC<OwnProps & PropsFromState> = (props) => {
    const [postingTitle, setPostingTitle] = useState<string>('');
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [milestoneFlag, setMilestoneFlag] = useState<boolean>(false);
    const [action, setAction] = useState<string>('');
    const [titleError, setTitleError] = useState<boolean>(false);
    const [startDateError, setStartDateError] = useState<boolean>(false);
    const [endDateError, setEndDateError] = useState<boolean>(false);

    useEffect(() => {
        if (props.startDate) {
            setStartDate(props.startDate);
        }
    }, [props.startDate]);

    useEffect(() => {
        if (props.endDate) {
            setEndDate(props.endDate);
        }
    }, [props.endDate]);

    useEffect(() => {
        if (props.post) {
            setPostingTitle(props.post.posting);
            setAction(props.post.action);
            const dateStart = parse(props.post.startDate, DATE_TIME_FORMAT, new Date());
            setStartDate(dateStart);
            const dateEnd = parse(props.post.endDate, DATE_TIME_FORMAT, new Date());
            setEndDate(dateEnd);
            setMilestoneFlag(props.post.milestoneFlag);
        }
    }, [props.post]);
    const displayCategory = () => props.currentCategory === 'MDWG'
        ? 'Modeling Development'
        : props.currentCategory === 'RA'
            ? 'Resource Adequacy'
            : (props.currentCategory || '');

    const changePostingTitle = (event: ChangeEvent, data: any) => {
        setPostingTitle(data.value);
        setTitleError(false);
    };

    const changeStartDate = (date: Date) => {
        if (endDate && endDate < date) {
            setStartDate(date);
            setEndDate(date);
        } else {
            setStartDate(date);
        }
    };

    const changeEndDate = (date: Date) => {
        if (startDate && startDate > date) {
            setEndDate(date);
            setStartDate(date);
        } else {
            setEndDate(date);
        }
    };

    const changeMilestoneFlag = () => {
        setMilestoneFlag((prevState) => !prevState);
    };

    const changeAction = (event: ChangeEvent, data: any) => {
        setAction(data.value);
    };

    const validatePosting = () => {
        const title = postingTitle === '';
        const start = !startDate;
        const end = !endDate
        setTitleError(title);
        setStartDateError(start);
        setEndDateError(end);

        return (title || start || end);
    };

    const clearForm = () => {
        setPostingTitle('');
        setAction('');
        setStartDate(undefined);
        setEndDate(undefined);
        setMilestoneFlag(false);
    };

    const createPosting = () => {
        if (validatePosting()) {
            return;
        }

        const posting = {
            id: null,
            posting: postingTitle,
            action: action,
            category: props.currentCategory,
            startDate: format(startDate as Date, DATE_TIME_FORMAT),
            endDate: format(endDate as Date, DATE_TIME_FORMAT),
            milestoneFlag: milestoneFlag
        };

        axios.post('/api/auth/postings', posting).then(response => {
            props.toggle(response.data);
            clearForm();
        }).catch(response => {
            toast.error(`Error occurred adding a new posting - ${response.message}`)
        });
    };

    const updatePosting = () => {
        if (validatePosting()) {
            return;
        }

        const posting = {
            id: props.post.id,
            posting: postingTitle,
            action: action,
            category: props.currentCategory,
            startDate: format(startDate as Date, DATE_TIME_FORMAT),
            endDate: format(endDate as Date, DATE_TIME_FORMAT),
            milestoneFlag: milestoneFlag
        };

        axios.put('/api/auth/postings', posting)
            .then(resp => {
                props.toggle(resp.data);
            })
            .catch(error => {
                toast.error(`Error occurred adding a new posting - ${error.message}`)
            });
    };

    return (
        <Modal open={props.open} closeOnDimmerClick={true} closeIcon={true} onClose={props.onClose}>
            <Modal.Header>{ props.editPosting ? 'Edit' : 'Create new'} posting for {displayCategory()}</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Input name={'postingTitle'} label={'Posting Title'} error={titleError}
                                value={postingTitle} required={true}
                                labelPosition={'left'} onChange={changePostingTitle}/>
                    <Grid>
                        <Grid.Column stretched={true} width={8}>
                            <Form.Group>
                                <Form.Field label={'Start Date'}
                                            control={DatePicker}
                                            error={startDateError}
                                            required={true}
                                            onChange={changeStartDate}
                                            selected={startDate}
                                            dateFormat={'MM/dd/yyyy'}/>
                                <Form.Field label={'End Date'}
                                            control={DatePicker}
                                            error={endDateError}
                                            required={true}
                                            onChange={changeEndDate}
                                            selected={endDate}
                                            dateFormat={'MM/dd/yyyy'}/>
                            </Form.Group>
                        </Grid.Column>
                        <Grid.Column verticalAlign={"middle"} width={8}>
                            <Form.Checkbox label={'Milestone Flag'} onChange={changeMilestoneFlag} defaultChecked={milestoneFlag}/>
                        </Grid.Column>
                    </Grid>
                    <Form.Input name={'action'} label={'Action'} labelPosition={'left'} value={action} onChange={changeAction}/>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                {
                    props.editPosting
                        ? <Button size={'mini'} color={'black'} onClick={updatePosting}>Update</Button>
                        : <Button size={'mini'} color={'black'} onClick={createPosting}>Submit</Button>
                }
            </Modal.Actions>
        </Modal>
    );
};

const mapStateToProps = (state: any) => ({
    currentUser: state.authReducer.currentUser,
    currentCategory: state.defaultReducer.currentCategory,
    userEntities: state.authReducer.userEntities,
    roles: state.authReducer.roles,
    sppStaff: state.authReducer.userRolesEntities.sppStaff
});

export default connect<PropsFromState, {}, OwnProps>(mapStateToProps, {})(PostingModal);